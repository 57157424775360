import React, { Component } from 'react'
import Api from '../../../api/api'
// import { route } from 'preact-router'
import { EntityType, TableState, Overview, Chromecast, AppState } from '../../../state/definition'
import { Redirect, withRouter, RouteComponentProps } from 'react-router'
import { AdminContext } from '../../../App';
import AdminNavigation from '../../../components/adminNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faTable } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import actions from '../../../state/actions';
import { UnregisterCallback, Location, Action } from 'history';
import 'moment-timezone'
import 'react-table/react-table.css'
import { uniqBy } from 'lodash'


interface StateProps {
    table: TableState
    loading: boolean
    //pagination: TableState


}

interface ParentProps {

}

interface State {
    viewPerPage: any
    orderBySelect: string
    sortBy: string
    search: string
    chrome_id: number
    customer: string
    getCustomer: []
}

type Props = ParentProps & StateProps & RouteComponentProps

let chromedatas: any
let gCustomer: any
export class Overviews extends Component<Props, State> {

    unregisterRouterListenerCallback?: UnregisterCallback

    constructor(props: any) {
        super(props)
        this.state = {
            viewPerPage: 100,
            orderBySelect: 'created',
            sortBy: 'DESC',
            search: '',
            chrome_id: 0,
            customer: '',
            getCustomer: []
        }
        this.handleChange = this.handleChange.bind(this)

    }

    async componentWillMount() {

        try {
            //const fetchoverview = await Api.overview.getAll(this.state.orderBySelect, this.state.sortBy)
            const fetchoverview = await Api.overview.getAll()
            chromedatas = fetchoverview.data.content

            const cust = await Api.overview.getCustomer()
            gCustomer= cust.data
            
            
            //if (this.props.chromecastHistoryId == undefined) {
            await this.fetchContent()
            this.unregisterRouterListenerCallback = this.props.history.listen(async (location: Location, action: Action) => {
                if (location.pathname === '/admin/overview') {
                    await this.fetchContent()
                }
            }
                // })
                //} else {
                /* await this.fetchChromecast()
                this.setState({
                    chrome_id: this.props.chromecastHistoryId
                }) */
                //}

            )
        }
        catch (e) {
            console.log('[ERROR CHROMECAST FETCH]', e)
        }
    }

    componentWillUnmount() {
        this.unregisterRouterListenerCallback && this.unregisterRouterListenerCallback()

        this.setState({
            chrome_id: 0
        })
        actions.chromecastHistoryId.setChromecastHistoryId(0)

    }

    async fetchContent(curPage: number = 0) {
        try {

            /* const fetchOverviewResponse = await Api.overview.getAll()
            actions.overview.setOverview(fetchOverviewResponse.data) */


            const fetchResponse = await Api.overview.getAllPages(this.state.orderBySelect, this.state.sortBy, this.state.viewPerPage, curPage, this.state.customer)
            actions.overview.setOverview(fetchResponse.data)

            
            

        } catch (e) {
            console.log('[ERROR OVERVIEW 1 FETCH]', e)
        }
    }

    async fetchChromecast(curPage: number = 0) {
        try {
            let chromecastHistoryId = 0
            if (chromecastHistoryId != 0) {
                const fetchResponse = await Api.overview.getOverview(this.state.orderBySelect, this.state.sortBy, this.state.viewPerPage, curPage)
                actions.overview.setOverview(fetchResponse.data)
            } else {
                this.setState({
                    chrome_id: 0
                }, () => { this.fetchContent() })
            }
        } catch (e) {
            console.log('[ERROR OVERVIEW 2 FETCH]', e)
        }
    }

    handleNextPage = (cPage: number) => {
        if (this.state.chrome_id == 0) {
            if (cPage < this.props.table.totalPages) {
                const nextPage = cPage
                nextPage && this.fetchContent(nextPage)
            }
        } else {
            if (cPage < this.props.table.totalPages) {
                const nextPage = cPage
                nextPage && this.fetchChromecast(nextPage)
            }
        }
    }

    handlePreviousPage = (cPage: number) => {
        if (this.state.chrome_id == 0) {
            if (cPage == 1) {
                if (this.state.search == '') {
                    this.fetchContent()
                }

            }
            if (cPage > 0) {
                const previousPage = cPage - 1
                if (this.state.search == '') {
                    previousPage && this.fetchContent(previousPage)
                }
            }
        } else {
            if (cPage == 1) {
                if (this.state.search == '') {
                    this.fetchChromecast()
                }

            }
            if (cPage > 0) {
                const previousPage = cPage - 1
                if (this.state.search == '') {
                    previousPage && this.fetchChromecast(previousPage)
                }
            }
        }

    }
    viewPerPageChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let safeSearchTypeValue: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                viewPerPage: safeSearchTypeValue
            }, () => { this.fetchContent() })
        } else {
            this.setState({
                viewPerPage: safeSearchTypeValue
            }, () => { this.fetchChromecast() })
        }

    }
    orderByChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let order: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                orderBySelect: order
            }, () => { this.fetchContent() })
        } else {
            this.setState({
                orderBySelect: order
            }, () => { this.fetchChromecast() })
        }
    }
    sortByChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let sort: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                sortBy: sort
            }, () => { this.fetchContent() })
        } else {
            this.setState({
                sortBy: sort,
                orderBySelect: this.state.orderBySelect
            }, () => { this.fetchChromecast() })
        }
    }

    selectCustom(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let sCustomer: string = event.currentTarget.value

        this.setState({
            customer: sCustomer
        }, () => { this.fetchContent() })

    }

    /*  async selectChromecast(event: React.ChangeEvent<HTMLSelectElement>) {
         let chrome_id = event.currentTarget.value
         let numm = parseInt(chrome_id)
         await actions.chromecastHistoryId.setChromecastHistoryId(numm)
         if (this.props.chromecastHistoryId != 0) {
             this.setState({
                 chrome_id: numm
             }, () => { this.fetchChromecast() })
         } else {
             this.setState({
                 chrome_id: numm
             }, () => { this.fetchContent() })
         }
     } */
    handleChange(event: any) {
        this.setState({ search: event.target.value })
    }


    
    render() {

        let dataColumns = null
        const overview = this.props.table && this.props.table.content

        dataColumns = overview && overview.map((overview: Overview) => {
            return (

                <tr key={overview.id}>
                    <td>{overview.id}</td>
                    <td>{overview.deviceId}</td>
                    <td><Moment format="DD.MM.YYYY - HH:mm" >{overview.created}</Moment></td>
                    <td>{overview.devicesReported}</td>
                    <td>{overview.devicesOnline}</td>
                    <td>{overview.devicesApproved}</td>
                    <td>{overview.state}</td>
                </tr>

            )

        })
        
        const customData = gCustomer && gCustomer.map((d:any) => {
            return (

                <option key={d.deviceId} value={d.deviceId}>{d.deviceId}</option>
            )
        })

        /* const customData = uniqBy(overview, 'deviceId').map((d: any) => {

            return (

                <option key={d.id} value={d.deviceId}>{d.deviceId}</option>
            )
        }) */


        const { table } = this.props
        const currentPages = this.props.table && this.props.table.currentPage + 1

        return (
            <React.Fragment>
                <AdminNavigation />
                <div className="container">

                    <h1>Heartbeat</h1>

                    <div className="backBody elements__Position">
                        <label htmlFor="rows" className="control">
                            <span> View per Page: </span>
                            <select className="select dropdown" id="viewPerPage" onChange={e => this.viewPerPageChange(e)} value={this.state.viewPerPage}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </label>
                        {this.state.chrome_id == 0 &&
                            <label htmlFor="order" className="control">
                                <span> Order by: </span>
                                <select className="select dropdown" id="orderBy" onChange={e => this.orderByChange(e)} value={this.state.orderBySelect}>
                                    <option value="created">Created</option>
                                    <option value="id">by ID</option>
                                    {/* <option value="chromecastIpAddress">Chromecast IP</option>*/}
                                </select>

                            </label>
                        }
                        {this.state.chrome_id > 0 &&
                            <label /*style={{ display: 'none' }}*/ htmlFor="order" className="control">
                                <span> Order by: </span>
                                <select className="select dropdown" id="orderBy" onChange={e => this.orderByChange(e)} value={this.state.orderBySelect}>
                                    <option value="created">Created</option>
                                    <option value="id">by ID</option>
                                    {/*  <option value="chromecastIpAddress">Chromecast IP</option>*/}
                                </select>

                            </label>
                        }
                        {this.state.chrome_id == 0 &&
                            <label htmlFor="sort" className="control">
                                <span> Sort: </span>
                                <select className="select dropdown" id="sortBy" onChange={e => this.sortByChange(e)} value={this.state.sortBy}>
                                    <option value="DESC">DESC</option>
                                    <option value="ASC">ASC</option>
                                </select>
                            </label>
                        }
                        {this.state.chrome_id > 0 &&
                            <label /*style={{ display: 'none' }}*/ htmlFor="sort" className="control">
                                <span> Sort: </span>
                                <select className="select dropdown" id="sortBy" onChange={e => this.sortByChange(e)} value={this.state.sortBy}>
                                    <option value="DESC">DESC</option>
                                    <option value="ASC">ASC</option>
                                </select>
                            </label>
                        }

                        <label htmlFor="chrome_id" className="control">
                            <span> Chromecasts: </span>

                            <select className="select dropdown" id="custom" onChange={e => this.selectCustom(e)} value={this.state.chrome_id}>
                                <option value="0" >Select Customer</option>
                                {customData}
                            </select>

                        </label>

                        <span> Total Elements : </span><span className="total ">{table && table.totalElements}</span>

                    </div><br></br>
                    <table className="table is-striped is-bordered is-hoverable is-fullwidth table__distanz">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Device Id</th>
                                <th>Created</th>
                                <th>Devices Registered</th>
                                <th>Devices Reported</th>
                                <th>Devices Approved</th>
                                <th>State</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataColumns}
                        </tbody>
                    </table>
                    {table &&
                        <div className="backBody"> <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                            <a className="pagination-previous" onClick={() => { this.handlePreviousPage(currentPages - 1) }}>Previous</a>
                            <a className="pagination-next" onClick={() => { this.handleNextPage(currentPages) }}>Next page</a>
                            <ul className="pagination-list" >
                                {this.state.chrome_id == 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchContent() }}>1</a></li>
                                }
                                {this.state.chrome_id > 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchChromecast() }}>1</a></li>
                                }

                                <li><span className="pagination-ellipsis">&hellip;</span></li>
                                {currentPages > 1 &&
                                    <li><a className="pagination-link" onClick={() => { this.handlePreviousPage(currentPages - 1) }}>{currentPages - 1}</a></li>
                                }
                                {currentPages == 1 &&
                                    <li style={{ display: 'none' }}><a className="pagination-link" onClick={() => { this.fetchContent() }}>{currentPages}</a></li>
                                }
                                {currentPages < table.totalPages &&
                                    <li><a className="pagination-link is-current" >{currentPages}</a></li>
                                }
                                {currentPages >= table.totalPages &&
                                    <li><a className="pagination-link is-current" >{table.totalPages}</a></li>
                                }
                                {currentPages < table.totalPages &&
                                    <li><a className="pagination-link" onClick={() => { this.handleNextPage(currentPages) }}>{currentPages + 1}</a></li>
                                }
                                {currentPages == table.totalPages &&
                                    <li style={{ display: 'none' }}><a className="pagination-link" onClick={() => { this.handleNextPage(currentPages) }}>{table.totalPages}</a></li>
                                }
                                <li><span className="pagination-ellipsis">&hellip;</span></li>
                                {this.state.chrome_id == 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchContent(this.props.table && this.props.table.totalPages - 1) }}>{this.props.table && this.props.table.totalPages}</a></li>
                                }
                                {this.state.chrome_id > 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchChromecast(this.props.table && this.props.table.totalPages - 1) }}>{this.props.table && this.props.table.totalPages}</a></li>
                                }


                            </ul>
                        </nav>
                        </div>
                    }
                </div>
            </React.Fragment>
        )

    }
}


const OverviewWithRouter = withRouter<Props>(Overviews)

export default (props: {}): any => (
    <AdminContext.Consumer>
        {(value: any) => {

            return (
                <OverviewWithRouter {...props} loading={value.common} table={value.table[EntityType.OVERVIEW] ? value.table[EntityType.OVERVIEW] : null} />
            )
        }}
    </AdminContext.Consumer>
)