import React, { Component } from 'react'
import AdminNavigation from '../../../components/adminNavigation'
import Api from '../../../api/api'
import actions from '../../../state/actions'
import { Chromecast, AppState, TableState, EntityType } from '../../../state/definition'
import { AdminContext } from '../../../App'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faHistory, faTrash } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { Location, Action, UnregisterCallback } from 'history'

import 'moment-timezone'
interface StateProps {
    table: TableState 
    loading: boolean
    
}

interface ParentProps {

}

interface State {
    totalElements: number
    totalPages: number
    pageSize: number
    currentPage: number
    licence: Array<any>
    id: number
    validFrom: string
    validTo: string
    created: string
    chrome_id:number
    viewPerPage: any
    orderBySelect: string
    sortBy: string
    search: string
    chromecastHistoryId: number
}
//let timerId: any
type Props = ParentProps & StateProps & RouteComponentProps
export class ChromecastTable extends Component<Props, State> {

    unregisterRouterListenerCallback?: UnregisterCallback

    constructor(props: Props) {
        super(props)
        this.routeChange = this.routeChange.bind(this)
        this.state = {
            totalElements: 0,
            totalPages: 0,
            pageSize: 100,
            currentPage: 0,
            licence: [],
            id: 99,
            validFrom: '',
            validTo: '',
            created: '',
            chrome_id: 0,
            viewPerPage: 25,
            orderBySelect: 'created',
            sortBy: 'DESC',
            search: '',
            chromecastHistoryId: 0
        }
    }

    async componentWillMount() {
        try {
            await this.fetchContent()
            this.unregisterRouterListenerCallback = this.props.history.listen(async (location: Location, action: Action) => {
                if (location.pathname === '/admin/devices') {
                    await this.fetchContent()
                }
            })
            
        }
        catch (e) {
            console.log('[ERROR CHROMECAST FETCH]', e)
        }
    }

    componentWillUnmount() {
        this.unregisterRouterListenerCallback && this.unregisterRouterListenerCallback()
    }

    /*async fetchContent2() {
        try {
            const fetchResponse = await Api.devices.getAll()
            actions.chromecast.setChromecasts(fetchResponse.data)
            //console.log('test test : ', fetchResponse.data.content)
            this.setState({
                totalElements: fetchResponse.data.totalElements,
                totalPages: fetchResponse.data.totalPages,
                pageSize: fetchResponse.data.pageSize,
                currentPage: fetchResponse.data.currentPage + 1,
                licence: fetchResponse.data.content
            })

        } catch (e) {
            console.log('[ERROR CHROMECAST FETCH]', e)
        }
    }*/



    routeChange(id: number) {

        actions.chromecastHistoryId.setChromecastHistoryId(id)

        let path = `../admin/history`
        this.props.history.push(path)

    }
    async fetchContent(curPage: number = 0) {
        try {

            const fetchChromecastResponse = await Api.devices.getAll()
            actions.chromecast.setChromecasts(fetchChromecastResponse)

            const fetchResponse = await Api.devices.getAll2(this.state.orderBySelect, this.state.sortBy, this.state.viewPerPage, curPage)
            actions.chromecast.setChromecasts(fetchResponse)
            //console.log(fetchChromecastResponse)
            this.setState({
                totalElements: fetchChromecastResponse.data.totalElements,
                totalPages: fetchChromecastResponse.data.totalPages,
                pageSize: fetchChromecastResponse.data.pageSize,
                currentPage: fetchChromecastResponse.data.currentPage + 1,
                licence: fetchChromecastResponse.data.content
            })

        } catch (e) {
            console.log('[ERROR HISTORY FETCH]', e)
        }
        
    }

    async fetchChromecast(curPage: number = 0) {
        try {
            if (this.state.chromecastHistoryId != 0) {
                const fetchResponse = await Api.devices.getAll2(this.state.orderBySelect, this.state.sortBy,  this.state.viewPerPage, curPage)
                actions.history.setHistory(fetchResponse.data)
                //console.log('chrome-id', this.props.chromecastHistoryId)
            } else {
                this.setState({
                    chrome_id: 0
                }, () => { this.fetchContent() })
            }
        } catch (e) {
            console.log('[ERROR HISTORY FETCH]', e)
        }
    }

    handleNextPage = (cPage: number) => {
        if (this.state.chrome_id == 0) {
            if (cPage < this.state.totalPages) {
                const nextPage = cPage
                nextPage && this.fetchContent(nextPage)
            }
        } else {
            if (cPage < this.state.totalPages) {
                const nextPage = cPage
                nextPage && this.fetchChromecast(nextPage)
            }
        }
    }

    handlePreviousPage = (cPage: number) => {
        if (this.state.chrome_id == 0) {
            if (cPage == 1) {
                if (this.state.search == '') {
                    this.fetchContent()
                }

            }
            if (cPage > 0) {
                const previousPage = cPage - 1
                if (this.state.search == '') {
                    previousPage && this.fetchContent(previousPage)
                }
            }
        } else {
            if (cPage == 1) {
                if (this.state.search == '') {
                    this.fetchChromecast()
                }

            }
            if (cPage > 0) {
                const previousPage = cPage - 1
                if (this.state.search == '') {
                    previousPage && this.fetchChromecast(previousPage)
                }
            }
        }

    }
    viewPerPageChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let safeSearchTypeValue: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                viewPerPage: safeSearchTypeValue
            }, () => { this.fetchContent() })
        } else {
            this.setState({
                viewPerPage: safeSearchTypeValue
            }, () => { this.fetchChromecast() })
        }

    }
    orderByChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let order: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                orderBySelect: order
            }, () => { this.fetchContent() })
        } else {
            this.setState({
                orderBySelect: order
            }, () => { this.fetchChromecast() })
        }
    }
    sortByChange(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault()
        let sort: string = event.currentTarget.value
        if (this.state.chrome_id == 0) {
            this.setState({
                sortBy: sort
            }, () => { this.fetchContent() })
        } else {
           this.setState({
                sortBy: sort,
                orderBySelect: this.state.orderBySelect
            }, () => { this.fetchChromecast() })
        }
    }
    async selectChromecast(event: React.ChangeEvent<HTMLSelectElement>) {
        let chrome_id = event.currentTarget.value
        let numm = parseInt(chrome_id)
        await actions.chromecastHistoryId.setChromecastHistoryId(numm)
        if (this.state.chromecastHistoryId != 0) {
            this.setState({
                chrome_id: numm
            }, () => { this.fetchChromecast() })
        } else {
            this.setState({
                chrome_id: numm
            }, () => { this.fetchContent() })
        }
    }
    handleChange(event: any) {
        this.setState({ search: event.target.value })
    }
    render() {
        let elements = null

        elements =
            <table style={{ width: '100%' }}>
                <tbody >
                    <tr >
                        <td>All Devices: {this.state.totalElements}</td>
                        <td style={{ width: '50px' }}></td>
                        <td>All Pages: {this.state.totalPages}</td>
                        <td style={{ width: '50px' }}></td>
                        <td>Elements on Page: {this.state.pageSize}</td>
                        <td style={{ width: '50px' }}></td>
                        <td>Current Page: {this.state.currentPage}</td>
                    </tr>

                </tbody>
            </table>

        let table2 = this.state.licence.map(name => {
            return (
                <tr key={name.licence.id}>
                    <td>{name.licence.id}</td>
                    <td>{name.deviceId}</td>
                    <td><Moment format="DD.MM.YYYY - HH:mm" >{name.lastRequest}</Moment></td>
                    <td><Moment format="DD.MM.YYYY - HH:mm" >{name.licence.validFrom}</Moment> </td>
                    {name.licence.validTo ?
                        <td><Moment format="DD.MM.YYYY - HH:mm" >{name.licence.validTo}</Moment></td> : <td>Unlimited</td>
                    }
                    <td><Moment format="DD.MM.YYYY - HH:mm" >{name.licence.created}</Moment> </td>
                    <td>{name.clientLicenceState}</td>
                </tr>

            )
        })
        const { table }  = this.props 
        
        const currentPages = this.state && this.state.currentPage + 1
        return (
            <React.Fragment>
                <AdminNavigation />
                <div className="container backBody">

                    {/* <LandingPageBackground /> */}
                    <h1> Device Overview</h1>
                    <div className="backBody elements__Position">
                    <label htmlFor="rows" className="control">
                        <span> View per Page: </span>
                        <select className="select dropdown" id="viewPerPage" onChange={e => this.viewPerPageChange(e)} value={this.state.viewPerPage}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                    {this.state.chrome_id == 0 &&
                        <label htmlFor="order" className="control">
                            <span> Order by: </span>
                            <select className="select dropdown" id="orderBy" onChange={e => this.orderByChange(e)} value={this.state.orderBySelect}>
                                <option value="created">Created</option>
                                {/*<option value="chromecastRoom">Chromecast Name</option>
                                <option value="chromecastIpAddress">Chromecast IP</option>*/}
                            </select>

                        </label>
                    }
                    {this.state.chrome_id > 0 &&
                        <label /*style={{ display: 'none' }}*/ htmlFor="order" className="control">
                            <span> Order by: </span>
                            <select className="select dropdown" id="orderBy" onChange={e => this.orderByChange(e)} value={this.state.orderBySelect}>
                                <option value="created">Created</option>
                                {/*<option value="chromecastRoom">Chromecast Name</option>
                                <option value="chromecastIpAddress">Chromecast IP</option>*/}
                            </select>

                        </label>
                    }
                  {/*  {this.state.chrome_id == 0 &&
                        <label htmlFor="sort" className="control">
                            <span> Sort: </span>
                            <select className="select dropdown" id="sortBy" onChange={e => this.sortByChange(e)} value={this.state.sortBy}>
                                <option value="DESC">DESC</option>
                                <option value="ASC">ASC</option>
                            </select>
                        </label>
                    }
                    {this.state.chrome_id > 0 &&
                        <label style={{ display: 'none' }} htmlFor="sort" className="control">
                            <span> Sort: </span>
                            <select className="select dropdown" id="sortBy" onChange={e => this.sortByChange(e)} value={this.state.sortBy}>
                                <option value="DESC">DESC</option>
                                <option value="ASC">ASC</option>
                            </select>
                        </label>
                    }

                    <label htmlFor="chrome_id" className="control">
                        <span> Chromecasts: </span>

                        <select className="select dropdown" id="chrome_id" onChange={e => this.selectChromecast(e)} value={this.state.chrome_id}>
                            <option value="0" >---</option>
                            {liste}
                        </select>

                </label>*/}

                    <span> Total Elements : </span><span className="total ">{this.state.totalElements}</span>

                    {/* <form className="control" onSubmit={this.handleSubmit}>
                        <label>
                            <span> Search: </span>
                            <input className="input input__search" type="text" placeholder="search" value={this.state.search} onChange={this.handleChange} />
                            <input type="submit" value="Submit" />
                        </label>
                    </form> */}
</div>
                    <br></br>
                    <table className="table is-striped is-bordered is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Device Name</th>
                                <th>Last Request</th>
                                <th>Valid from</th>
                                <th>Valid to</th>
                                <th>Device created</th>
                                <th>Client Licence State</th>
                            </tr>
                        </thead>
                        <tbody>
                            {table2}
                        </tbody>
                        
                    </table>
                    {table &&
                    <div className="backBody"> <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                                    <a className="pagination-previous" onClick={() => { this.handlePreviousPage(currentPages - 1) }}>Previous</a>
                                    <a className="pagination-next" onClick={() => { this.handleNextPage(currentPages) }}>Next page</a>
                                  
                                    <ul className="pagination-list" >
                                {this.state.chrome_id == 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchContent() }}>1</a></li>
                                }
                                

                                <li><span className="pagination-ellipsis">&hellip;</span></li>
                                {currentPages > 2 &&
                                    <li><a className="pagination-link" onClick={() => { this.handlePreviousPage(currentPages -1) }}>{currentPages - 1}</a></li>
                                }
                                {currentPages == 1 &&
                                    <li style={{ display: 'none' }}><a className="pagination-link" onClick={() => { this.fetchContent() }}>{currentPages}</a></li>
                                }
                                {currentPages < this.state.totalPages &&
                                    <li><a className="pagination-link is-current" >{currentPages}</a></li>
                                }
                                {currentPages >= this.state.totalPages &&
                                    <li><a className="pagination-link is-current" >{this.state.totalPages}</a></li>
                                }
                                {currentPages < this.state.totalPages &&
                                    <li><a className="pagination-link" onClick={() => { this.handleNextPage(currentPages) }}>{currentPages + 1}</a></li>
                                }
                                {currentPages == this.state.totalPages &&
                                    <li style={{ display: 'none' }}><a className="pagination-link" onClick={() => { this.handleNextPage(currentPages) }}>{table.totalPages}</a></li>
                                }
                                <li><span className="pagination-ellipsis">&hellip;</span></li>
                                {this.state.chrome_id == 0 &&
                                    <li><a className="pagination-link" onClick={() => { this.fetchContent(this.props.table && this.props.table.totalPages -1) }}>{this.state.totalPages}</a></li>
                                }
                                


                            </ul>
                                </nav>
                                </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}

const ChromecastTableWithRouter = withRouter<Props>(ChromecastTable)

export default (props: {}): any => (
    <AdminContext.Consumer>
        {(value: any) => {
            return (
                <ChromecastTableWithRouter {...props} loading={value.common} table={value.table[EntityType.CHROMECAST] ? value.table[EntityType.CHROMECAST] : null} />
            )
        }}
    </AdminContext.Consumer>
)