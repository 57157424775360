import axios, { AxiosInstance, AxiosResponse } from 'axios'

export class Api {
    axiosInstance: AxiosInstance

    responseSuccessCallBack: (response: AxiosResponse<any>|Promise<AxiosResponse<any>>) => any = (response) => {}

    responseErrorCallBack: (error: any) => any = (error) => {}

    user = {
        login: (username: string = '', password: string = ''): Promise<any> => {
            const params = { username, password }
            return this.axiosInstance.post('user/login?sessionheader=false', params, {
                withCredentials: false
            })
        },
        logout: (): Promise<any> => this.axiosInstance.post('user/logout'),
        getCurrent: (): Promise<any> => this.axiosInstance.get('user/current'),
    }

    /*chromecast = {
        getAll: (): Promise<any> => this.axiosInstance.get('admin/chromecast/list'),

        //edit: (): Promise<any> => this.axiosInstance.get(`admin/chromecast/list/${chromecastId}`),

        delete: (chromecastId: number): Promise<any> => this.axiosInstance.delete(`/admin/chromecast/${chromecastId}`),
    }*/
    devices = {
        getAll2: (orderby:string, sort: string, limit: number, page: number): Promise<any> => this.axiosInstance.get(`public/request/list/last?orderBy=${orderby}&direction=${sort}&limit=${limit}&page=${page}`),
        getAll: (): Promise<any> => this.axiosInstance.get('public/request/list/last?limit=100'),

        //edit: (): Promise<any> => this.axiosInstance.get(`admin/chromecast/list/${chromecastId}`),

        delete: (chromecastId: number): Promise<any> => this.axiosInstance.delete(`/admin/chromecast/${chromecastId}`),
    }
    licenses = {
        getAll: (orderby:string, sort: string, limit: number, page: number): Promise<any> => this.axiosInstance.get(`admin/licence/list?orderBy=${orderby}&direction=${sort}&limit=${limit}&page=${page}`),

        delete: (approvalId: number): Promise<any> => this.axiosInstance.delete(`/admin/approval/${approvalId}`),
    }

    publicApproval = {
        get: (chromecastCode: string): Promise<any> => this.axiosInstance.get(`/public/approve/${chromecastCode}`),

        getStatus: (approvalId: number): Promise<any> => this.axiosInstance.get(`public/approve/${approvalId}/status`)
    }

    requests = {
        getAll: (orderby:string, sort: string, limit: number, page: number): Promise<any> => this.axiosInstance.get(`public/request/list?orderBy=${orderby}&direction=${sort}&limit=${limit}&page=${page}`),

        getSearch: (orderby: string, search: string, sort: string, limit: number, page:number): Promise<any> => this.axiosInstance.get(`admin/approvalhistory/list?filter[0].fieldName=${orderby}&filter[0].comparison=LIKE&filter[0].fieldCriteria=${search}&filter[0].negated=false&direction=${sort}&limit=${limit}&page=${page}`),
        
        getChromecast: (orderby:string, sort: string, limit: number, page: number,historyid:number):Promise<any> => this.axiosInstance.get(`admin/approvalhistory/list?orderBy=${orderby}&filter[0].fieldName=chromecastId&filter[0].comparison=EQUALS&filter[0].fieldCriteria=${historyid}&direction=${sort}&limit=${limit}&page=${page}`)
    }

    overview = {
        //getAll: (orderby:string, sort:string): Promise<any> => this.axiosInstance.get(`public/request/verify/list?orderBy=${orderby}&direction=${sort}`),
        getAll: (): Promise<any> => this.axiosInstance.get(`public/request/verify/list?orderBy=created&direction=DESC`),
        getOverview: (orderby:string, sort: string, limit: number, page: number):Promise<any> => this.axiosInstance.get(`public/request/verify/list?orderBy=${orderby}&filter[0].fieldName=chromecastId&filter[0].comparison=EQUALS&direction=${sort}&limit=${limit}&page=${page}`),
        getAllPages: (orderby:string, sort: string, limit: number, page: number, customer: string):Promise<any> => this.axiosInstance.get(`public/request/verify/list?orderBy=${orderby}&direction=${sort}&limit=${limit}&page=${page}&filter[0].fieldName=deviceId&filter[0].comparison=LIKE&filter[0].fieldCriteria=${customer}`),
        
        getCustomer: ():Promise<any> => this.axiosInstance.get(`public/request/verify/list/last?limit=100`),
    }

    info = {
        getAll: (): Promise<any> => this.axiosInstance.get('admin/report/info')
    }

    constructor() {
        this.axiosInstance = axios.create({
            //baseURL: 'https://chromelicence.bitsfabrik.com/api/',
            baseURL: '/api/',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse<any>|Promise<AxiosResponse<any>>) => response,
            (error: any) => this.responseErrorCallBack(error),
        )
    }

}

export default new Api()