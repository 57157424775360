import React, { Component } from 'react'
import { HashRouter as Router, Route } from 'react-router-dom'
import Home from './routes/home'
import AdminLogin from './routes/admin/login'
import Success from './routes/success'
import ChromecastTable from './routes/admin/devices'
import History from './routes/admin/requests'
import Info from './routes/admin/info'
import ApprovalTable from './routes/admin/licenses'
import { AppState } from './state/definition'
import { Switch, Redirect, RouteProps, RouteComponentProps, withRouter } from 'react-router'
import Actions from './state/actions'
import Api from './api/api'
import actions from './state/actions'
import Overview from './routes/admin/overview'


export const AdminContext: React.Context<any> = React.createContext({
    auth: { user: null, permissions: [], loggedIn: false },
    common: { loading: false },
    success: false,
    table: {},
    connection: {
        chromecastCode: '',
        isConnecting: false,
        isConnected: false,
        errorMessage: '',
    },
    route: '',
    chromecastHistroyId: 0
})

class PrivateRoute extends React.Component<RouteProps, {}> {
    render() {
        const children = this.props.children
        return (
            <Route {...this.props}>
                <AdminContext.Consumer>
                    {value => {
                        return (
                            value.auth.loggedIn ? children : <Redirect to='/admin/login' />
                        )
                    }}
                </AdminContext.Consumer>
            </Route>
        )
    }
}

class Routes extends React.Component<RouteComponentProps, {}> {

    componentWillMount() {
        if(this.props.location) {
            actions.route.setRoute(this.props.location.pathname)
        }
    }

    componentWillReceiveProps(nextProps: RouteComponentProps) {
        if (nextProps.location !== this.props.location) {
            actions.route.setRoute(nextProps.location.pathname)
        }
    }

    render() {
        console.log('history', this.props.history)
        return (
            <AdminContext.Consumer>
                {(state: AppState) => {
                    return (
                        <Switch>

                            <Route
                                render={
                                    (props: RouteComponentProps) => { 
                                        return state.auth.loggedIn ?
                                         <Redirect to="/admin/devices" /> : <AdminLogin />
                                    }
                                }
                                
                                path="/admin/login"
                            />

                            <PrivateRoute path="/admin/licenses">
                                <ApprovalTable />
                            </PrivateRoute>

                            <PrivateRoute path="/admin/devices">
                                <ChromecastTable />
                            </PrivateRoute>

                            <PrivateRoute path="/admin/overview">
                                <Overview />
                            </PrivateRoute>

                            <PrivateRoute path="/admin/requests">
                                <History />
                            </PrivateRoute>

                            <PrivateRoute path="/admin/info">
                                <Info />
                            </PrivateRoute>

                            <Route
                                render={(props: RouteComponentProps) => <Redirect to="/admin/devices" />}                                
                                path="/" exact={true}
                                />

                        </Switch>
                    )}
                }

            </AdminContext.Consumer>
        )
    }
}

const RoutesWithRouter = withRouter(Routes)

class App extends Component<{}, AppState> {
    constructor(props: any) {
        super(props)
        Actions.ctx = this
        this.state = {
            auth: {
                user: null,
                permissions: [],
                loggedIn: false
            },
            common: {
                loading: true
            },
            table: {},
            success: false,
            connection: {
                chromecastCode: '',
                isConnecting: false,
                isConnected: false,
                errorMessage: '',
            },
            route: '',
            chromecastHistroyId: 0
            
        }
    }

    async componentWillMount() {
        Api.responseErrorCallBack = (error) => {
            if (error.response.status == 401 && this.state.route.includes('admin')) {
                actions.auth.setUserData(null, [], false)
            }
            actions.common.setLoading(false)
            return Promise.reject(error)
        }

        try {
            const response = await Api.user.getCurrent()
            actions.auth.setUserData(response.data.user, response.data.permissions)
            actions.common.setLoading(false)
        } catch (e) {
            console.log('[ERROR LOGIN] No current user.', e)
        }
    }

    render() {
        const { success } = this.state
        // console.log('[App State]', this.state)
        return (
            <div id="app">
                {this.state.common.loading ?
                    <span>Loading</span> :
                    <AdminContext.Provider value={{ ...this.state }}>
                        <Router>
                            <RoutesWithRouter />
                        </Router>
                    </AdminContext.Provider>
                }
            </div>
        )
    }
}

export default App