import React, { Component } from 'react'
import Api from '../../../api/api'
import AdminLoginForm from './adminLoginForm'
import { AdminContext } from '../../../App'
import actions from '../../../state/actions'
import { ErrorMessage } from 'formik';
import { timeout } from 'q';

interface AdminFormProps {
    onSubmit: (values: FormValues, setSubmitting: (status: boolean) => void) => void
    
}

export interface FormValues {
    username: string
    password: string
}

interface Props {
}

interface State {
    loading: boolean,
    errorLoginMessage: string

}

export class AdminLogin extends Component<Props, State> {

    constructor(props: any) {
        super(props)
        this.state = {
            loading: false,
            errorLoginMessage: ''
        }


        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async handleSubmit(data: FormValues, setSubmitting: (status: boolean) => void) {
        try {
            const response = await Api.user.login(data.username, data.password)
            actions.auth.setUserData(response.data.user, response.data.permissions)
            setSubmitting(false)
            actions.common.setLoading(false)
        } catch (e) {
            if (e.response.status == 400) {
                this.setState({
                    errorLoginMessage: 'Username or Password is wrong. Please try again.'
                })
                
            }
            console.log('[LOGIN ERROR]', e)
            setSubmitting(false)
            actions.common.setLoading(false)
        }
    }
    handleChange(){

    }
    render() {

        return (
            <div className="modal is-active">
                <div className="modal-background" />
                <div className="modal-content">
                    <div className="box">
                        <AdminLoginForm onSubmit={this.handleSubmit} />
                    </div>{
                        this.state.errorLoginMessage != '' &&
                        <div className="home__error">
                            {this.state.errorLoginMessage}
                        </div>
                    }
                </div>
                
            </div>
        )
    }
}

export default (props: Props) => (
    <AdminContext.Consumer>
        {value => {
            return (
                <AdminLogin {...props} />

            )
        }}
    </AdminContext.Consumer>
)