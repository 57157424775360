import React, { Component } from 'react'

export default class ValidatorBubble extends Component<Object, Object> {
    render() {
        return (
            <div className="validator-bubble">
                {this.props.children}
            </div>
        )
    }
}
