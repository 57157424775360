
import React, { Component } from 'react'
// import { route } from 'preact-router'
import { withFormik, FormikProps, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'
import ValidatorBubble from '../../../components/validatorBubble'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
interface AdminFormProps {
    onSubmit: (values: FormValues, setSubmitting: (status: boolean) => void) => void
    
}

export interface FormValues {
    username: string
    password: string
}

const innerForm = (props: AdminFormProps & FormikProps<FormValues>) => {
    const { isSubmitting } = props
    return (
        <Form>
            <div className="field">
                <label className="label" htmlFor="name">Username</label>
                <ErrorMessage component={ValidatorBubble} name="username" />
                <div className="control"></div>
                <Field type="text" name="username" id="formUsername" className="input" />
            </div>
            <div className="field">
                <label className="label" htmlFor="pass">Password</label>
                <ErrorMessage component={ValidatorBubble} name="password" />
                <div className="control">
                    <Field type="password" name="password" id="formPassword" className="input" required />
                </div>
            </div>
            <div className="field">
                <div className="control">
                    <button type="submit" className={`button is-primary ${isSubmitting ? 'is-loading' : ''}`} disabled={isSubmitting}>
                    <FontAwesomeIcon icon={faSignInAlt} transform='left-4' />
                    Login</button>
                </div>
            </div>
        </Form >
    )
}

const AdminLoginForm = withFormik<AdminFormProps, FormValues>({
    mapPropsToValues: (props: AdminFormProps) => {
        return {
            username: '',
            password: '',
        }
    },
    validationSchema: (props: AdminFormProps) => Yup.object().shape({
        username: Yup.string()
            .min(2, 'Username is too short')
            .max(50, 'Username is too long')
            .required('Username is is required'),
        password: Yup.string()
            .min(2, 'Password is too short')
            .max(50, 'Password is too long')
            .required('Password is is required'),
    }),
    handleSubmit: (values, { props, setSubmitting }) => {
        props.onSubmit(values, setSubmitting)
    }
})(innerForm)

export default AdminLoginForm

