import { Stream } from "stream";

export interface AppState {
    auth: AuthState
    common: {
        loading: boolean
    }
    success?: boolean
    table?: { [entityType: string]: TableState }
    connection: ConnectionState
    route: string
    chromecastHistroyId: number
}

export interface TableState {
    totalElements: number
    totalPages: number
    pageSize: number
    currentPage: number
    content?: Array<any>

}

export interface AuthState {
    permissions: Array<PermissionType>
    user: User | null
    loggedIn: boolean
}

export interface ConnectionState {
    chromecastCode: string
    isConnecting: boolean
    isConnected: boolean
    errorMessage: string
}

// export interface UserPermissionsResponse {
//     user: User | null,
//     permissions: Array<PermissionType>
// }

export interface User {
    id: number
    username: string
    email: string
    nick: string
    firstname: string
    lastname: string
    zip: string
    city: string
    gender: Gender
    birthdate: string
    roles: string
    provider: string
    socialId: string
    socialProfilePicture: string
    lastlogin: string
}

export interface Chromecast {
    deviceId: string    
    lastRequest: string
    clientLicenceState: string
    licence?: Array <any>
    
}

export interface Overview {
    id: number
    deviceId: string    
    created: string
    devicesReported: number
    devicesOnline: number
    devicesApproved: number
    state: string
}

export interface Licence {
    id: number
    validFrom: string
    validTo: String
    created: number
}

//admin.approval : Admin Device Licence Controller
export interface Approval {
    id: number
    licenceType: string
    deviceId: string
    validFrom: string
    created: string   
    validTo: string 
    devicesGranted: number
}

export interface Info {
    state: string,
    deviceId: string,
    devicesGranted: number,
    validTo: string,
    allChromecastsUnlicenced: boolean,
    unlicencedChromecasts: number,
    backendVersion: string,
    onlineChromecasts: number,
    approvedChromecasts: number
}

//public.request : Admin Device Request Controller
export interface History {
    created: string
    id: number
    devicehash: string
    devicesReported: string
    state: string
    devicesGranted: string
    validTo: string
    deviceId: string
}

export enum Gender {
    UNKNOWN = 'UNKNOWN',
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    ALL = 'ALL'
}

export enum PermissionType {
    PERM_ADMIN = 'PERM_ADMIN',
    ROLE_ADMIN = 'ROLE_ADMIN'
}

export enum EntityType {
    CHROMECAST = 'CHROMECAST',
    APPROVAL = 'APPROVAL',
    HISTORY = 'HISTORY',
    INFO = 'INFO',
    OVERVIEW = 'OVERVIEW'
}
