import React, { Component } from 'react'
import AdminNavigation from '../../../components/adminNavigation'
import Api from '../../../api/api'
import actions from '../../../state/actions'
import { TableState, EntityType, Info } from '../../../state/definition'
import { AdminContext } from '../../../App'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { Location, Action, UnregisterCallback } from 'history'
import { async } from 'q';
import Spinner from '../../../components/spinner/spinner'
import VersionInfo from '../../../version.json'
import { date } from 'yup';

interface ParentProps {
    table: TableState | null

}

interface State {
    states: string
    deviceId: string
    devicesGranted: number
    validTo: string
    deviceLicenceValidTo: string
    allChromecastsUnlicenced: boolean
    unlicencedChromecasts: number
    backendVersion: string
    onlineChromecasts: number
    approvedChromecasts: number
    allChromecasts: number
}

type Props = ParentProps & RouteComponentProps
export class InfoTable extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            states: '',
            deviceId: '',
            devicesGranted: 0,
            validTo: '',
            deviceLicenceValidTo: '',
            allChromecastsUnlicenced: false,
            unlicencedChromecasts: 0,
            backendVersion: '',
            onlineChromecasts: 0,
            approvedChromecasts: 0,
            allChromecasts: 0
        }
    }

    unregisterRouterListenerCallback?: UnregisterCallback

    async componentWillMount() {
        try {
            await this.fetchContent()
            this.unregisterRouterListenerCallback = this.props.history.listen(async (location: Location, action: Action) => {
                if (location.pathname === '/admin/info') {
                    await this.fetchContent()
                }
            })

        }
        catch (e) {
            console.log('[ERROR APPROVAL]', e)
        }
    }

    componentWillUnmount() {
        this.unregisterRouterListenerCallback && this.unregisterRouterListenerCallback()

    }

    async fetchContent() {
        try {
            const fetchResponse = await Api.info.getAll()

            if (fetchResponse.status != 200) {
                const logoutResponse = await Api.user.logout()
                logoutResponse && this.props.history.push('admin/login')
            } else {
                actions.info.setInfo(fetchResponse.data)
                console.log(fetchResponse.data.backendVersion)
                this.setState({
                    backendVersion: fetchResponse.data.backendVersion,
                    /*states: fetchResponse.data.licence.state,
                    deviceId: fetchResponse.data.licence.deviceId,
                    devicesGranted: fetchResponse.data.licence.devicesGranted,
                    validTo: fetchResponse.data.licence.validTo,
                    deviceLicenceValidTo: fetchResponse.data.licence.deviceLicenceValidTo,
                    allChromecastsUnlicenced: fetchResponse.data.allChromecastsUnlicenced,
                    unlicencedChromecasts: fetchResponse.data.unlicencedChromecasts,                    
                    onlineChromecasts: fetchResponse.data.onlineChromecasts,
                    approvedChromecasts: fetchResponse.data.approvedChromecasts,
                    allChromecasts: fetchResponse.data.allChromecasts*/
                })
            }
        } catch (e) {
            console.log('[ERROR INFO FETCH]', e)
        }
    }


    render() {
        /*var date = new Date(this.state.deviceLicenceValidTo); // some mock date
        var date2 = new Date();
        var date3 = date2.getTime()
        var milliseconds = date.getTime();

        let expire = ''
        if (milliseconds < date3) {
            expire = 'Expired'
        } else {
            expire = 'active until'
        }
        console.log('Datum jetzt' + date3)
        console.log('mili' + milliseconds)*/
        return (
            <React.Fragment>
                <AdminNavigation />
                <div className="container">
                    {/*<h1>License Information</h1>
                    <table className="table is-striped is-bordered is-hoverable is-fullwidth table__distanz">
                        <thead >
                            <tr>
                                <th style={{ width: '400px' }}>Licence</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>All Chromecasts Unlicenced</td>
                                {this.state.allChromecastsUnlicenced == true &&
                                    <td>ALL  </td>
                                }
                                {this.state.allChromecastsUnlicenced == false &&
                                    <td>{this.state.unlicencedChromecasts}   </td>
                                }
                            </tr>
                            <tr>
                                <td>Device Id</td>
                                <td>{this.state.deviceId}</td>
                            </tr>
                            <tr>
                                <td>Device Licence Valid To</td>
                                {this.state.deviceLicenceValidTo  && 
                                <td> {expire} <Moment format="DD.MM.YYYY - HH:mm" >{this.state.deviceLicenceValidTo}</Moment></td>
                                }
                            </tr>
                            <tr>
                                <td>Expire</td>
                                {this.state.deviceLicenceValidTo != null && this.state.states != 'EXPIRED' &&
                                    <td ><Moment format="DD.MM.YYYY - HH:mm" >{this.state.deviceLicenceValidTo}</Moment></td>
                                }
                                {this.state.deviceLicenceValidTo != null && this.state.states == 'EXPIRED' &&
                                    <td style={{ color: 'red' }}><Moment format="DD.MM.YYYY - HH:mm" >{this.state.deviceLicenceValidTo}</Moment></td>
                                }
                                {this.state.deviceLicenceValidTo == null &&
                                    <td> Unlimited</td>
                                }
                            </tr>
                            <tr>
                                <td>Chromecasts Granted</td>
                                <td>{this.state.devicesGranted}</td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>{this.state.states}</td>
                            </tr>
                            <tr>
                                <td>Refresh before</td>
                                <td><Moment format="DD.MM.YYYY - HH:mm" >{this.state.validTo}</Moment></td>
                            </tr>

                        </tbody>
                    </table>
                    <h1>Chromecasts Information</h1>
                    
                    <table className="table is-striped is-bordered is-hoverable is-fullwidth table__distanz">
                        <thead>
                            <tr>
                                <th style={{ width: '400px' }}>Chromecasts</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>All Chromecasts</td>
                                <td>{this.state.allChromecasts}</td>
                            </tr>
                            <tr>
                                <td>Online Chromecasts</td>
                                <td>{this.state.onlineChromecasts}</td>
                            </tr>
                            <tr>
                                <td>Approved Chromecasts</td>
                                <td>{this.state.approvedChromecasts}</td>
                            </tr>
                            <tr>
                                <td>Unlicenced Chromecasts</td>
                                {this.state.allChromecastsUnlicenced == true && this.state.unlicencedChromecasts == 0 &&
                                    <td style={{ color: 'red' }}>ALL  </td>
                                }

                                {this.state.unlicencedChromecasts > 0 &&
                                    <td style={{ color: 'red' }}>{this.state.unlicencedChromecasts}  </td>
                                }

                                {this.state.allChromecastsUnlicenced == false && this.state.unlicencedChromecasts == 0 &&
                                    <td>{this.state.unlicencedChromecasts}   </td>
                                }
                            </tr>
                        </tbody>
                    </table>*/}
                    <h1>Versions Information</h1>
                    <table className="table is-striped is-bordered is-hoverable is-fullwidth table__distanz">
                        <thead>
                            <tr>
                                <th style={{ width: '400px' }}>Info</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Backend Version</td>
                                <td>{this.state.backendVersion}</td>
                            </tr>
                            <tr>
                                <td>Frontend Version</td>
                                <td>{VersionInfo && VersionInfo.build.version} - {VersionInfo && VersionInfo.build.date}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                </div>
            </React.Fragment>
        )
    }
}

const InfoTableWithRouter = withRouter<Props>(InfoTable)

export default (props: Object) => (
    <AdminContext.Consumer>
        {(value: any) => {
            return (
                <InfoTableWithRouter {...props} table={value.table[EntityType.INFO] ? value.table[EntityType.INFO] : null} />
            )
        }}
    </AdminContext.Consumer>
)