import Api from '../api/api'
import { User, PermissionType, Chromecast, EntityType, TableState, Info } from './definition';

class Actions {
    ctx: any = {}

    auth = {
        setUserData: (user: User | null, permissions: Array<PermissionType>, loggedIn = true) => {
            this.ctx.setState({ auth: { user, permissions, loggedIn } })
        }
    }

    common = {
        setLoading: (loading: boolean) => {
            this.ctx.setState({ common: loading })
        }
    }

    chromecast = {
        setChromecasts: (chromecastState: TableState) => {
            this.ctx.setState({
                table: {
                    [EntityType.CHROMECAST]: chromecastState
                }
            })
        }
    }

    overview = {
        setOverview: (overviewState: TableState) => {
            this.ctx.setState({
                table: {
                    [EntityType.OVERVIEW]: overviewState
                }
            })
        }
    }

    approval = {
        setApprovals: (approvalState: TableState) => {
            this.ctx.setState({
                table: {
                    [EntityType.APPROVAL]: approvalState
                }
            })
        }
    }

    info = {
        setInfo: (infoState: TableState) => {
            this.ctx.setState({
                table: {
                    [EntityType.INFO]: infoState                    
                }
            })
        }
    }

    /*info = {
        setInfo: (state: string, deviceId: string, devicesGranted: number, validTo: string, allChromecastsUnlicenced: boolean, unlicencedChromecasts: number, backendVersion: string, onlineChromecasts: number, approvedChromecasts: number) => {
            this.ctx.setState({
                state,
                deviceId,
                devicesGranted,
                validTo,
                allChromecastsUnlicenced,
                unlicencedChromecasts,
                backendVersion,
                onlineChromecasts,
                approvedChromecasts
            }
            )
        }
    }*/

    history = {
        setHistory: (historyState: TableState) => {
            this.ctx.setState({
                table: {
                    [EntityType.HISTORY]: historyState
                }
            })
        }
    }

    connection = {
        setChromecastCode: (chromecastCode: string) => {
            this.ctx.setState({
                connection: {
                    chromecastCode
                }
            })
        },
        setConnecting: (isConnecting: boolean) => {
            this.ctx.setState({
                connection: {
                    isConnecting
                }
            })
        },
        setConnected: (isConnected: boolean) => {
            this.ctx.setState({
                connection: {
                    isConnected
                }
            })
        },
        setErrorMessage: (errorMessage: string, timeout?: number) => {
            this.ctx.setState({
                connection: {
                    errorMessage
                }
            })

            if (timeout) {
                setTimeout(() => {
                    this.ctx.setState({
                        connection: {
                            errorMessage: ''
                        }
                    })
                }, timeout)
            }
        }
    }

    route = {
        setRoute: (route: string) => {
            this.ctx.setState({
                route
            })
        }
    }

    chromecastHistoryId = {
        setChromecastHistoryId: (chromecastHistoryId: number) => {
            this.ctx.setState({
                chromecastHistoryId
            })
        }
    }
    publicApproval = {
        getStatus: (approvalId: number) => {
            this.ctx.setState({
                publicApprovel: {
                    approvalId
                }
            })
        }
    }

}

export default new Actions()