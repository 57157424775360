import React, { Component } from 'react'
import { withRouter, NavLink, Redirect } from 'react-router-dom'
import Api from '../../api/api'
import actions from '../../state/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faSignOutAlt, faHistory, faPlug, faInfoCircle, faHeartbeat} from '@fortawesome/free-solid-svg-icons'
import VersionInfo from '../../version.json'

class AdminNavigation extends React.Component<{}, {}> {
	render() {
		return (
			<header className="navigation">
				<nav className="navbar gradient">
					<NavLink activeClassName="active" className="nav-item" to="/admin/devices"><FontAwesomeIcon icon={faDesktop} transform='left-3' /> Devices</NavLink>
					<NavLink activeClassName="active" className="nav-item" to="/admin/requests"><FontAwesomeIcon icon={faHistory} transform='left-3' /> Requests</NavLink>
					<NavLink activeClassName="active" className="nav-item" to="/admin/overview"><FontAwesomeIcon icon={faHeartbeat} transform='left-3' /> Heartbeat</NavLink>
					<NavLink activeClassName="active" className="nav-item" to="/admin/licenses"><FontAwesomeIcon icon={faPlug} transform='left-3' /> Licenses</NavLink>					
					<NavLink activeClassName="active" className="nav-item" to="/admin/info"><FontAwesomeIcon icon={faInfoCircle} transform='left-3' /> Info</NavLink>
					{/* <NavLink activeClassName="active" className="nav-item is-primary" to="/admin/settings">Settings</NavLink> */}
					<div className="column">Admin CMS</div>
					<div className="info-item" style={{ fontSize: '12px'}}>Version: {VersionInfo && VersionInfo.build.version} - {VersionInfo && VersionInfo.build.date}</div>
					<NavLink activeClassName="active" className="nav-item is-danger" to="/admin/login">
						<div onClick={() => {
							Api.user.logout()
							actions.auth.setUserData(null, [], false)
						}}>
						<FontAwesomeIcon icon={faSignOutAlt} transform='left-3' /> Logout
						</div>
					</NavLink>
				</nav>
			</header>
		)
	}
}

export default AdminNavigation
